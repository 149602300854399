
// import { mapActions, mapGetters } from 'vuex'
// import constants from '@/utils/constants'

import {mapActions, mapGetters} from 'vuex'

export default {

  computed: {
    ...mapGetters('user', ['tokens']),
  },

  methods: {
    ...mapActions('user', ['fetchAccessTokens']),
    reloadToken () {
      const { id } = this.$route.params
      this.fetchAccessTokens({
        id,
      })
    },
  },

  beforeMount () {
  },
}

<template>
  <vs-table
    stripe
    v-model="selected"
    :hoverFlat="true"
    :data="tokens" >
    <template slot="thead">
      <vs-th class="thead">Name</vs-th>
      <vs-th class="thead">Created At</vs-th>
      <vs-th class="thead">Expired At</vs-th>
      <vs-th class="thead">Action</vs-th>
    </template>
    <template slot-scope="{data}">
      <vs-tr :data="item" :key="idx" v-for="(item, idx) in data">
        <vs-td>
          {{ item.name }}
        </vs-td>
        <vs-td>
          {{ $moment(item.created_at).format(datetimeFormat) }}
        </vs-td>
        <vs-td>
          {{ $moment(item.expires_at).format(datetimeFormat) }}
        </vs-td>
        <vs-td>
          <vs-button
            class="float-left m-0"
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-trash"
            @click="onTokenDelete(item)"/>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import formMixin from '@/mixins/user/formMixin'

export default {
  mixins: [formMixin],
  name: 'UserTokenTable',
  filters: {
    getTitle (value) {
      return value?.data?.title
    },
  },

  computed: {
    ...mapGetters('user', ['tokens']),
  },

  data:() => ({
    selected: [],
    datetimeFormat: 'D MMM YYYY HH:mm',
    dateFormat: 'D MMM YYYY HH:mm',
  }),

  methods: {
    ...mapActions('user', ['revokeAccessToken']),

    async onTokenDelete (data) {
      this.selected = data

      await this.revokeAccessToken({
        id: data.user_id,
        tokenId: data.id,
      })

      this.reloadToken()
    },
  },
}
</script>

<template>
  <div>
    <!-- Content Row -->

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          v-model="value.email"
          class="w-full mt-4"
          label="Username (Email)"
          type="email"
          v-validate="'required|email'"
          name="email"
          :disabled="value.id"
          autocomplete="username"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('email')">
          {{ errors.first('email') }}
        </span>

        <vs-input
          v-model="value.password"
          class="w-full mt-4"
          label="Password"
          type="password"
          v-validate="{ required: !value.id || !lodash.isEmpty(value.password_confirmation) }"
          name="password"
          ref="password"
          autocomplete="new-password"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('password')">
          {{ errors.first('password') }}
        </span>

        <vs-input
          v-model="value.password_confirmation"
          class="w-full mt-4"
          label="Confirm Password"
          type="password"
          v-validate="{ required: !value.id || !lodash.isEmpty(value.password), confirmed: 'password' }"
          data-vv-as="password"
          name="password_confirmation"
          autocomplete="new-password"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('password_confirmation')">
          {{ errors.first('password_confirmation') }}
        </span>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          v-model="value.name"
          class="w-full mt-4"
          label="Name"
          v-validate="'required'"
          name="name"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('name')">
          {{ errors.first('name') }}
        </span>

        <div class="mt-4">
          <label class="vs-input--label">Status</label>
          <v-select
            v-model="value.active"
            :clearable="false"
            :options="statusOptions"
            :reduce="status => status.value"
            v-validate="'required'"
            name="status"/>
          <span class="text-danger text-sm block mt-2" v-show="errors.has('status')">
            {{ errors.first('status') }}
          </span>
        </div>

        <div class="mt-4">
          <label class="vs-input--label">Role</label>
          <v-select
            v-model="value.roles"
            :clearable="false"
            :options="roleOptions"
            :reduce="role => role.label"
            v-validate="'required'"
            name="role"/>
          <span class="text-danger text-sm block mt-2" v-show="errors.has('role')">
            {{ errors.first('role') }}
          </span>
        </div>
      </div>
    </div>

    <div class="vx-row" v-if="value.id">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="dateDisplay(value.created_at)"
          class="w-full mt-4"
          label="Created At"
          disabled/>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="dateDisplay(value.updated_at)"
          class="w-full mt-4"
          label="Updated At"
          disabled/>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import constants from '@/utils/constants'

export default {
  name: 'UserAccount',
  props: {
    value: {
      type: Object,
    },
  },

  data:() => ({
    loaded: false,
    statusOptions: constants.statusOptions,
  }),
  computed: {
    ...mapGetters('data', ['roles']),
    roleOptions () {
      return this.roles.map((role) => ({
        label: role.name,
        value: role.id,
      }))
    },
  },
  methods: {
    validate () {
      return this.$validator.validateAll()
    },
    dateDisplay (date) {

      if (this.lodash.isEmpty(date)) {
        return ''
      }
      return this.$helpers.dateFormat(date, 'LLL')
    },
  },
}
</script>

<template>
  <section>
    <vx-card class="cardx thead">
      <form>
        <user-avatar v-model="info" ref="avatar" class="mb-4" />

        <user-account v-model="info" ref="account" />

        <!-- Save & Reset Button -->
        <div class="vx-row mt-6">
          <div class="vx-col w-full mt-4">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button
                class="ml-auto"
                @click.prevent="saveChanges"
                :disabled="locked || !hasErrors">
                Save Changes
              </vs-button>
              <vs-button
                class="ml-4"
                type="border"
                color="warning"
                :disabled="locked"
                @click="resetData">
                Reset
              </vs-button>
            </div>
          </div>
        </div>
      </form>
    </vx-card>

    <!-- Token -->
    <vs-col class="vx-col w-full" v-if="info.created_at">
      <vx-card class="cardx" title="Access Token">

        <user-token-table v-model="info" ref="tokens" />

        <hr class="my-4">

        <user-token v-model="info" ref="token" />
      </vx-card>
    </vs-col>

    <!-- Back Button -->
    <vs-row vs-justify="flex-start" class="my-4">
      <vs-button
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click.prevent="$router.go(-1)">
        Back
      </vs-button>
    </vs-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import infoMixin from '@/mixins/infoMixin'
import formMixin from '@/mixins/user/formMixin'
import schemas from '@/utils/schemas'
import UserAccount from '@/components/form/user/account'
import UserAvatar from '@/components/form/user/avatar'
import UserTokenTable from '@/components/table/UserToken'
import UserToken from '@/components/form/user/token'

export default {
  mixins: [infoMixin, formMixin],
  components: {
    UserAccount,
    UserAvatar,
    UserTokenTable,
    UserToken,
  },
  data:() => ({
    schema: schemas.user(),
    info: schemas.user(),
  }),
  computed: {
    ...mapGetters('data', ['roles']),
    ...mapGetters('user', ['tokens']),
    ...mapGetters('user', {
      $info: 'info',
    }),
  },
  methods: {
    ...mapActions('data', ['fetchRoles']),
    ...mapActions('user', ['find', 'create', 'update', 'reset', 'fetchAccessTokens', 'generateAccessToken', 'revokeAccessToken']),

    async _find ({ id }) {
      this.locked = true

      await this.find({ id })

      this.locked = false
    },

    async validateForm () {
      const validates = [
        // this.$refs.avatar.validate(),
        this.$refs.account.validate(),
      ]

      const valids = await Promise.all(validates)

      return valids.every(valid => valid)
    },
    async saveChanges () {
      const valid = await this.validateForm()

      /* eslint-disable */
      if (!valid) {
        this.$vs.notify({
          title: 'Error',
          text: 'Please complete this form',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'warning'
        })
        return
      }

      this.locked = true

      await Promise.all([
          this.info.id ?
            this.update({ id: this.info.id, data: this.info }) :
            this.create({ data: this.info })
        ])
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: `User has been ${this.info.id ? 'updated' : 'created'}`,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Update fail',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
        .finally(() => {
          this.locked = false
        })
    },
    async deleteToken( id, tokenId ) {
      await this.revokeAccessToken({ id: id, tokenId: tokenId })
      this.reloadToken()
    },

    async onTokenDelete (data) {
      this.selected = data

      await this.revokeAccessToken({ id: data.user_id, tokenId: data.id })
      this.reloadToken()
    }
  },
  beforeMount() {
    if (this.roles.length === 0) {
      this.fetchRoles()
    }
  },
  async mounted () {
    if (this.lodash.has(this.$route, 'params.id')) {
      this._find({
        id: this.lodash.get(this.$route, 'params.id')
      })
      this.reloadToken()
    }
  }
}
</script>

<style lang="scss">
.cardx {
  margin-bottom: 20px;
}
.cardx .vx-card__body, .cardx .vx-card__body .con-vs-tabs, .cardx .vx-card__body .con-vs-tabs .con-slot-tabs {
  overflow: visible !important;
}
</style>

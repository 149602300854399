<template>
  <form>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          v-model="token.name"
          class="w-full mt-4"
          label="Name"
          v-validate="'required'"
          name="name"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('name')">
          {{ errors.first('name') }}
        </span>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-button
          transition="fadeOut"
          class="mt-10"
          icon-pack="feather"
          icon="icon-plus-square"
          @click.prevent="submit"
          :disabled="locked">
          Generate New Token
        </vs-button>
      </div>
      <div class="vx-col w-full" v-if="token_created.token">
        <hr class="my-4">
        <h3>New Access Token</h3>

        <p class="text-danger">
          Make sure to copy your new personal access token now. You won’t be able to see it again!
        </p>

        <vs-input
          v-model="token_created.name"
          class="w-full mt-4"
          label="Name"
          readonly
          />
        <vs-textarea
          v-model="token_created.token"
          class="w-full mt-4"
          label="Token"
          readonly
          name="created_token"
          id="created_token"
          />
        <vs-button
          icon-pack="feather"
          icon="icon-clipboard"
          @click.prevent="copyToken()">
          Copy Token
        </vs-button>
      </div>
    </div>
  </form>
</template>

<script>
import formMixin from '@/mixins/user/formMixin'
import { mapActions, mapGetters } from 'vuex'
import schemas from '@/utils/schemas'

export default {
  mixins: [formMixin],
  name: 'UserToken',

  data:() => ({
    locked: false,
    token: schemas.token(),
    token_created: schemas.token(),
  }),

  computed: {
    ...mapGetters('user', {
      $token: 'token',
    }),
  },

  methods: {
    ...mapActions('user', ['generateAccessToken']),

    validate () {
      return this.$validator.validateAll()
    },

    async submit () {
      const valid = await this.validate()

      if (valid) {
        this.$vs.loading()

        try {
          const { id } = this.$route.params

          this.token_created = await this.generateAccessToken({
            id,
            data: {
              name: this.token.name,
            },
          })

          this.token = schemas.token()

          this.reloadToken()
        } finally {
          this.$vs.loading.close()
        }
      }
    },
    copyToken () {
      navigator.clipboard.writeText(this.token_created.token)
    },
  },

  mounted () {
  },

  watch: {
  },
}
</script>

<template>
  <!-- Avatar Row -->
  <div class="vx-row">
    <div class="vx-col w-full">
      <div class="flex items-center flex-col sm:flex-row sm:items-end">

        <vs-avatar
          :src="avatarUrl"
          class="m-0 mr-8 h-24 w-24"/>

        <!-- <img  class="mr-8 rounded h-24 w-24" /> -->
        <!-- <vs-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
        <div class="mt-2 sm:mt-0">
          <input
            type="file"
            class="hidden"
            ref="updateAvatarInput"
            @change="chooseAvatar"
            accept="image/*">

          <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
          <vs-button
            class="mr-4"
            @click="$refs.updateAvatarInput.click()"
            :disabled="locked">
            {{ value.avatar ? 'Change Avatar' : 'Upload Avatar' }}
          </vs-button>

          <vs-button v-if="value.avatar"
            type="border"
            color="danger"
            @click.prevent="removeAvatar"
            :disabled="locked">
            Remove Avatar
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UserAvatar',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data:() => ({
    locked: false,
    current: null,
    imageData: null,
  }),
  computed: {
    avatarUrl: {
      get () {
        if (!this.lodash.isEmpty(this.imageData)) {
          return this.imageData
        }

        return this.current
      },
      set (data) {
        this.imageData = data
      },
    },
  },
  methods: {
    ...mapActions('user', ['uploadAvatar', 'deleteAvatar']),

    chooseAvatar () {
      const self = this

      const file = this.lodash.get(this.$refs, 'updateAvatarInput.files[0]')
      if (file) {
        self.locked = true

        const reader = new FileReader
        reader.onload = (e) => {
          self.imageData = e.target.result

          self.uploadAvatar({
            id: self.value.id,
            params: {
              avatar: self.imageData,
            },
          })
            .then((user) => {
              // self.setAvatar(self.lodash.get(user, 'avatar'))
              self.lodash.set(self.value, 'avatar', self.lodash.get(user, 'avatar'))

              self.lodash.set(self.$refs.updateAvatarInput, 'value', null)
            })
            .catch(() => {
              self.rollbackAvatar()
            })
            .finally(() => {
              self.locked = false
            })
        }

        reader.onabort = () => {
          self.locked = false
        }

        reader.onerror = () => {
          self.locked = false
        }

        reader.readAsDataURL(file)
      }
    },
    removeAvatar () {
      this.deleteAvatar({
        id: this.value.id,
      })
        .then((user) => {
          this.setAvatar(this.lodash.get(user, 'avatar'))
        })
        .finally(() => {
          this.locked = false
        })
    },
    setAvatar (avatar) {
      // this.lodash.set(this.value, 'avatar', avatar)
      this.lodash.set(this, 'current', avatar)
      this.lodash.set(this, 'imageData', null)
    },
    rollbackAvatar () {
      this.lodash.set(this, 'current', this.lodash.get(this.value, 'avatar'))
      this.lodash.set(this, 'imageData', null)
    },
  },
  mounted () {
    if (!this.lodash.isEmpty(this.lodash.get(this.value, 'avatar'))) {
      this.lodash.set(this, 'current', this.lodash.get(this.value, 'avatar'))
    }

    this.locked = false
  },
  watch: {
    'value.avatar' (avatar) {
      // console.log({ watch: 'value.avatar', avatar })
      this.setAvatar(avatar)
    },
  },
}
</script>
